<template>
    <div
        v-dragscroll.x="true"
        class="mb-20 -mx-5 px-5 pb-2 grid grid-rows-4 auto-cols-auto overflow-x-scroll grid-flow-col content-center items-center gap-4"
    >
        <member
            v-for="item of list"
            :key="item.key"
            :logo="item.logo"
            :site="item.site"
        />
    </div>
</template>

<script>
import Member from "@/components/Landing/Participants/Member";
import { dragscroll } from 'vue-dragscroll'

export default {
    name: "ParticipantsGrid",
    components: { Member },
    directives: {
        'dragscroll': dragscroll
    },
    props: {
        list: {
            type: Array,
            default: () => []
        }
    }
};
</script>

<style scoped>
::-webkit-scrollbar {
  height: 4px; /* высота для горизонтального скролла */
  background-color: #fff;
}

/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
  background-color: #fbb724;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #ff9100;
}
</style>
